import React, { useContext, createContext, FunctionComponent } from 'react';
import { LiveDialogsStore } from '../store';

export const store: LiveDialogsStore = new LiveDialogsStore();

export const LiveDialogsStoreContext = createContext(store);

export const LiveDialogsStoreProvider: FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <LiveDialogsStoreContext.Provider value={store}>
      {children}
    </LiveDialogsStoreContext.Provider>
  );
};

export const useLiveDialogsStore = () => useContext(LiveDialogsStoreContext);
