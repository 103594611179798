import adminCenterClient, { Login } from './adminCenterEndPoint';
import delay from './delay';

export type Agent = {
  id: string;
  name: string;
  alias: string;
  role: string;
};

export type Agency = {
  id: string;
  name: string;
  shortened: string;
  rate_per_coin: number;
  rate_per_in: number;
  rate_per_out: number;
};

export type Team = {
  id: string;
  name: string;
  owner_hash: string | null;
  rate_per_coin: number;
  rate_per_in: number;
  rate_per_out: number;
  shortened: string;
};

export type Permission = {
  allowActions: string[];
};

export type User = {
  agent: Agent;
  team: Team;
  agency: Agency;
  token: string;
  permissions: string[];
};

export const login = async (
  username: string,
  password: string,
  agencyShort: string
): Promise<User> => {
  const user: User = await Login(username, password, agencyShort);

  return user;
};

export const IsServerOnline = () =>
  adminCenterClient
    .get('204')
    .then(() => {
      return true;
    })
    .catch(err => {
      if (err?.response?.status === 900) return false;
      return true;
    });

export const GetModTokenAndSecret = async () => {
  await delay(2000);
  return { token: 'geht dich', secret: 'nichts an' };
};
