import React, { useState, useEffect, useMemo, useRef } from 'react';
import { NextPage } from 'next';
import styled, { keyframes } from 'styled-components';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';
import Image from 'next/image';
import { motion, AnimatePresence } from 'framer-motion';
import { StyleGuide, InputTextWithIcon, Loader, Title } from '../Theme';
import { useTranslation } from '../i18n';
import { useAgentStore } from '../useStores';
import { User, login } from '../../services/login';
import { Permissions } from '../types';
import { useInterval, useMeasure } from '../hooks';
import { queryCache } from 'react-query';

const backgroundAnimation = keyframes`
  0% {
    opacity:0;
    z-index:10;
    transform:translateX(100%);
    animation-timing-function: ease-in;
  }
  1% {
    opacity:1;
    z-index:10;
    transform:translateX(0%);
  }
  20% {
    transform:translateX(0%);
    z-index:5;

    opacity:1;
  }
  21% {
    transform:translateX(-70%);
    z-index:5;

    animation-timing-function: ease-out;
    opacity:1;
  }
22%{
  opacity:0;
}

`;

const BGImageContainer = styled(motion.div)`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
`;

export const SlideImage = ({ src }: { src: string }) => {
  const ref = useRef(null);

  const { width: containerWidth, height: containerHeight } = useMeasure(ref);
  return (
    <AnimatePresence>
      <BGImageContainer
        ref={ref}
        key={src}
        initial={{ x: 50, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: -50, opacity: 0 }}
        transition={{
          type: 'spring',
          damping: 50,
          stiffness: 100,
          duration: 3
        }}
      >
        <motion.img
          src={src}
          height="100%"
          width="100%"
        // height={containerHeight || '1000px'}
        // width={containerWidth || '1920px'}
        />
      </BGImageContainer>
    </AnimatePresence>
  );
};

const BackgroundImage = styled.div<{ isRunning?: boolean }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  color: transparent;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: none;
  opacity: 0;
  z-index: 5;
  animation: ${backgroundAnimation} 50s linear infinite 0s;
  animation-play-state: ${({ isRunning }) =>
    isRunning ? 'running' : 'paused'};
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: ${StyleGuide.colors.modalBackground};
`;
const Text = styled.p`
  font-size: 0.8em;
  color: ${StyleGuide.colors.fontOnPrimary};
  text-align: center;
`;
const List = styled.ul`
  position: fixed;
  list-style-type: none;
  width: 100%;
  height: 100%;
  margin: 0px;
  top: 0px;
  left: 0px;
  z-index: 4;

  & li:nth-child(1) ${BackgroundImage} {
    background-image: url('/static/images/bg1.webp');
  }
  & li:nth-child(2) ${BackgroundImage} {
    background-image: url('/static/images/bg2.webp');
    animation-delay: 10s;
  }
  & li:nth-child(3) ${BackgroundImage} {
    background-image: url('/static/images/bg3.webp');
    animation-delay: 20s;
  }
  & li:nth-child(4) ${BackgroundImage} {
    background-image: url('/static/images/bg4.webp');
    animation-delay: 30s;
  }
  & li:nth-child(5) ${BackgroundImage} {
    background-image: url('/static/images/bg5.webp');
    animation-delay: 40s;
  }
`;

const imgs = [
  '/static/images/bg1.webp',
  '/static/images/bg2.webp',
  '/static/images/bg3.webp',
  '/static/images/bg4.webp',
  '/static/images/bg5.webp'
];

const ModalLayer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 900;
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10%;
`;

const ContentContainer = styled.div`
  background: ${StyleGuide.colors.loginBackground};
  display: flex;
  min-width: 250px;
  flex-direction: column;
  border-radius: 5px;
  flex-direction: column;
  padding: 40px;
`;

const Button = styled.input`
  align-self: stretch;
  background: ${StyleGuide.colors.primary};
  color: ${StyleGuide.colors.fontOnPrimary};
  height: 50px;
  margin-top: 10px;
  border-radius: 25px;
  cursor: pointer;
  outline: inherit;
  border: none;
  text-transform: uppercase;
`;

const TextInput = styled(InputTextWithIcon)`
  margin: 5px 10px;
  font-size: 12px;
  font-family: Roboto;
  font-weight: 400;

  ::placeholder {
    opacity: 0.8;
    letter-spacing: 0.45em;
    font-size: 12px;
    font-weight: 400;
  }
`;

const Logo = styled(Image)`
  margin: 15px 0px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 20px;
`;

/**
 * Stellt den Login für die Website bereit und leitet bei erfolgreicher Authentifizierung auf das Dashboard weiter.
 */
const Login: NextPage = observer(() => {
  const router = useRouter();
  const { setAgent, agent } = useAgentStore();
  const { t } = useTranslation(['login', 'common']);
  const [cookies, setCookie] = useCookies(['Token']);
  const [isLoading, setIsLoading] = useState(false);
  const [organization, setOrganization] = useState('');
  const [username, setusername] = useState('');
  const [password, setPassword] = useState('');
  const [shownError, setShownError] = useState<string | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    queryCache.invalidateQueries('dashboardTeams');
  }, []);

  useInterval(() => setCurrentIndex((currentIndex + 1) % imgs.length), 10000, {
    leading: true
  });

  const currentPic = useMemo(() => imgs[currentIndex], [currentIndex]);

  useEffect(() => {
    if (cookies?.Token && agent) router.push('/dashboard');
  }, []);

  const onSubmit = (name: string, pw: string, orga: string) => {
    setIsLoading(true);
    login(name, pw, orga)
      .then((res: User) => {
        setIsLoading(false);

        const obj: Permissions = {};
        res.permissions.forEach(key => {
          // @ts-ignore
          obj[key] = true;
        });
        // @ts-ignore
        setCookie('Perms', obj, {
          path: '/',
          expires: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000)
        });
        setCookie('Token', res.token, {
          path: '/',
          expires: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000)
        });
        setAgent(res);
        router.push('/dashboard');
      })
      .catch(err => {
        setIsLoading(false);
        const { statusCode } = err;

        switch (statusCode) {
          case 21003:
            setShownError('inactiveAccount');
            break;
          default:
            setShownError('invalidEntries');
            console.log(err, 'loginError');
        }
      });
  };

  const [isWindowFocused, setIsWindowFocused] = useState(true);

  return (
    <Background
      onMouseEnter={() => setIsWindowFocused(true)}
      onMouseLeave={() => setIsWindowFocused(false)}
    >
      <Title text="Admin Center" />
      <SlideImage src={currentPic} />
      <ModalLayer>
        <Container>
          <ContentContainer>
            <Logo src="/static/images/logo.png" width="328px" height="220px" />
            <Form
              onSubmit={event => {
                event.preventDefault();
                if (!username || !password || !organization) {
                  setShownError('missingInputs');
                  return;
                }
                setShownError(null);
                onSubmit(username, password, organization);
              }}
            >
              <TextInput
                iconName="world"
                error={!!shownError}
                value={organization}
                placeholder={t('organization')}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  const val = e.currentTarget.value;
                  setOrganization(val.trim());
                }}
              />
              <TextInput
                iconName="user"
                error={!!shownError}
                value={username}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  const val = e.currentTarget.value;
                  setusername(val.trim());
                }}
                placeholder={t('username')}
              />
              <TextInput
                iconName="lock"
                error={!!shownError}
                value={password}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  const val = e.currentTarget.value;
                  setPassword(val.trim());
                }}
                placeholder={t('password')}
                type="password"
              />

              {shownError && <Text>{t(shownError)}</Text>}

              {/* @ts-ignore */}
              <Button type="submit" value={t('login')} />
            </Form>
          </ContentContainer>

          {isLoading && <Loader notFixed={false} />}
        </Container>
      </ModalLayer>
    </Background>
  );
});

export default Login;
