import React, { useContext, createContext, FunctionComponent } from 'react';
import { DashboardStore } from '../store';

export const store: DashboardStore = new DashboardStore();

export const DashboardStoreContext = createContext(store);

export const DashboardStoreProvider: FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <DashboardStoreContext.Provider value={store}>
      {children}
    </DashboardStoreContext.Provider>
  );
};

export const useDashboardStore = () => useContext(DashboardStoreContext);
