import React from 'react';
import Login from '../components/LoginPage';

const Page = () => {
  return <Login />;
};
async function getInitialProps() {
  return {
    namespacesRequired: ['common', 'login']
  };
}

Page.getInitialProps = getInitialProps;

export default Page;
